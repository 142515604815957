import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CreateTeam = () => {
  const [newTeam, setNewTeam] = useState({ name: '' });
  const [newTeamLeagueRelation, setNewTeamLeagueRelation] = useState({
    teams: [],
    league: '',
    group: 'A'  // Set default group to 'A'
  });
  const [teams, setTeams] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [teamsResponse, leaguesResponse] = await Promise.all([
          axios.get(process.env.REACT_APP_API_URL + '/api/teams/', {
            headers: {
              'Authorization': `Token ${localStorage.getItem('authToken')}`
            }
          }),
          axios.get(process.env.REACT_APP_API_URL + '/api/leagues/', {
            headers: {
              'Authorization': `Token ${localStorage.getItem('authToken')}`
            }
          })
        ]);
  
        setTeams(teamsResponse.data);
        setLeagues(leaguesResponse.data);
      } catch (error) {
        setError('Failed to load teams or leagues');
        console.error(error);
      }
    };
    fetchData();
  }, []);
  
  const handleTeamInputChange = (e) => {
    const { name, value } = e.target;
    setNewTeam(prevTeam => ({
      ...prevTeam,
      [name]: value,
    }));
  };

  const handleTeamLeagueRelationChange = (e) => {
    const { name, value } = e.target;
    if (name === 'teams') {
      const selectedOptions = Array.from(e.target.selectedOptions).map(option => option.value);
      setNewTeamLeagueRelation(prev => ({
        ...prev,
        teams: selectedOptions
      }));
    } else {
      setNewTeamLeagueRelation(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleTeamSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        process.env.REACT_APP_API_URL + '/api/new-team/',
        newTeam,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${localStorage.getItem('authToken')}`
          },
          withCredentials: true,
        }
      );

      setSuccessMessage(`New team created: ${newTeam.name}`);
      setNewTeam({ name: '' });
      setError(null);
      
      const teamsResponse = await axios.get(process.env.REACT_APP_API_URL + '/api/teams/', {
        headers: {
          'Authorization': `Token ${localStorage.getItem('authToken')}`
        },
      });
      setTeams(teamsResponse.data);
    } catch (error) {
      setError(error.response?.data?.error || 'Error creating team');
    }
  };

  const handleTeamLeagueRelationSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(process.env.REACT_APP_API_URL + '/api/team-league-relations/', newTeamLeagueRelation, {
        headers: {
          'Authorization': `Token ${localStorage.getItem('authToken')}`
        },
      });
      setNewTeamLeagueRelation({ teams: [], league: '', group: 'A' }); // Reset with default group 'A'
      setSuccessMessage('Teams added to league successfully');
    } catch (error) {
      setError('Could not add teams to league');
    }
  };

  return (
    <div className="space-y-6">    
      <div>
        <h3 className="text-lg font-semibold">Skapa nytt lag</h3>
        <form onSubmit={handleTeamSubmit} className="bg-gray-100 p-4 rounded-lg shadow-md">
          <input
            type="text"
            name="name"
            placeholder="Lagnamn"
            value={newTeam.name}
            onChange={handleTeamInputChange}
            required
            className="border p-2 rounded w-full mt-2"
          />
          <button type="submit" className="bg-blue-500 text-white px-4 py-1 mt-4 rounded-lg hover:bg-blue-600">
            Skapa lag
          </button>
        </form>
      </div>

      <div>
        <h3 className="text-lg font-semibold">Lägg till lag i serie</h3>
        <form onSubmit={handleTeamLeagueRelationSubmit} className="bg-gray-100 p-4 rounded-lg shadow-md">
          <select
            multiple
            name="teams"
            value={newTeamLeagueRelation.teams}
            onChange={handleTeamLeagueRelationChange}
            className="border p-2 rounded w-full mt-2 h-32"
          >
            {teams.map(team => (
              <option key={team.id} value={team.id}>
                {team.name}
              </option>
            ))}
          </select>
          <p className="text-sm text-gray-600 mt-1">Ctrl/Cmd för att välja flera</p>
          
          <select
            name="league"
            value={newTeamLeagueRelation.league}
            onChange={handleTeamLeagueRelationChange}
            className="border p-2 rounded w-full mt-4"
          >
            <option value="">Välj serie</option>
            {leagues.map(league => (
              <option key={league.id} value={league.id}>
                {league.name}
              </option>
            ))}
          </select>

          <select
            name="group"
            value={newTeamLeagueRelation.group}
            onChange={handleTeamLeagueRelationChange}
            className="border p-2 rounded w-full mt-4"
          >
            <option value="A">Grupp A</option>
            <option value="B">Grupp B</option>
          </select>
          
          <button 
            type="submit" 
            className="bg-blue-500 text-white px-4 py-1 mt-4 rounded-lg hover:bg-blue-600"
            disabled={!newTeamLeagueRelation.teams.length || !newTeamLeagueRelation.league}
          >
            Lägg till i serie
          </button>
        </form>
      </div>

      {successMessage && (
        <p className="text-green-500">{successMessage}</p>
      )}
      {error && (
        <p className="text-red-500">{error}</p>
      )}
    </div>
  );
};

export default CreateTeam;