import React, { useState } from 'react';
import MatchResults from './MatchResults';
import CreateMatches from './CreateMatches';
import PlayoffMatches from './PlayoffMatches';
import SubTabButton from '../Common/SubTabButton';

const MatchesTab = ({ activeSubTab }) => {
  const [playoffDataFetched, setPlayoffDataFetched] = useState(false);

  const openSubTab = (event, tabName) => {
    const tabcontent = document.getElementsByClassName("tabcontent");
    for (let i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    const tablinks = document.getElementsByClassName("tablinks");
    for (let i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }

    document.getElementById(tabName).style.display = "block";
    event.currentTarget.className += " active";

    if (tabName === 'PlayoffMatches' && !playoffDataFetched) {
      setPlayoffDataFetched(true);
    }
  };

  return (
    <div>
      <div className="subtab">
        <SubTabButton onClick={(e) => openSubTab(e, 'MatchResults')} label="Resultat" />
        <SubTabButton onClick={(e) => openSubTab(e, 'CreateMatches')} label="Matcher" />
        <SubTabButton onClick={(e) => openSubTab(e, 'PlayoffMatches')} label="Slutspelsmatcher" />
      </div>

      <div id="MatchResults" className="tabcontent" style={{ display: activeSubTab === 'MatchResults' ? 'block' : 'none' }}>
        <MatchResults />
      </div>

      <div id="CreateMatches" className="tabcontent" style={{ display: activeSubTab === 'CreateMatches' ? 'block' : 'none' }}>
        <CreateMatches />
      </div>
      
      <div id="PlayoffMatches" className="tabcontent" style={{ display: activeSubTab === 'PlayoffMatches' ? 'block' : 'none' }}>
        {playoffDataFetched && <PlayoffMatches />}
      </div>
    </div>
  );
};

export default MatchesTab;
