import React from 'react';
import CreateTeam from './CreateTeam';
import AllTeams from './AllTeams';
import SubTabButton from '../Common/SubTabButton';

const TeamsTab = ({ activeSubTab }) => {
  const openSubTab = (event, tabName) => {
    const tabcontent = document.getElementsByClassName("tabcontent");
    for (let i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    
    const tablinks = document.getElementsByClassName("tablinks");
    for (let i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    
    document.getElementById(tabName).style.display = "block";
    event.currentTarget.className += " active";
  };

  return (
    <div>
      <div className="subtab">
        <SubTabButton onClick={(e) => openSubTab(e, 'CreateNewTeams')} label="Skapa nytt lag" />
        <SubTabButton onClick={(e) => openSubTab(e, 'AllTeams')} label="Hantera lag" />
      </div>

      <div id="CreateNewTeams" className="tabcontent" style={{ display: activeSubTab === 'CreateNewTeams' ? 'block' : 'none' }}>
        <CreateTeam />
      </div>

      <div id="AllTeams" className="tabcontent" style={{ display: activeSubTab === 'AllTeams' ? 'block' : 'none' }}>
        <AllTeams />
      </div>
    </div>
  );
};

export default TeamsTab;