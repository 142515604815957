import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('authToken');
        if (token) {
            setIsLoggedIn(true);

            // Hämta användardata från backend om det behövs
            const fetchUserData = async () => {
                try {
                    const response = await fetch(process.env.REACT_APP_API_URL + '/api/auth/custom-user/', {
                        headers: {
                            'Authorization': `Token ${token}`,
                        },
                    });

                    if (!response.ok) {
                        throw new Error('Failed to fetch user data');
                    }

                    const userData = await response.json();
                    // Sätter användaren och inkluderar .isAdmin
                    setUser({
                        ...userData,
                        isAdmin: userData.is_staff, // Förväntas från API
                    });
                } catch (error) {
                    console.error('Error fetching user data:', error);
                    setIsLoggedIn(false);
                    setUser(null);
                }
            };

            fetchUserData();
        }
    }, []);

    const value = {
        isLoggedIn,
        setIsLoggedIn,
        user,
        setUser,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};