import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const Playoff = () => {
    const { leagueId } = useParams();
    const [matches, setMatches] = useState({
        winnerBracket: {
            quarterfinals: [],
            semifinals: [],
            finals: []
        },
        loserBracket: {
            quarterfinals: [],
            semifinals: [],
            finals: []
        }
    });

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/playoffmatches/`)
            .then(response => {
                const winnerMatches = {
                    quarterfinals: response.data.quarterfinals.matches.filter(m => m.bracket_type === 'winner') || [],
                    semifinals: response.data.semifinals.matches.filter(m => m.bracket_type === 'winner') || [],
                    finals: response.data.finals.matches.filter(m => m.bracket_type === 'winner') || []
                };

                const loserMatches = {
                    quarterfinals: response.data.quarterfinals.matches.filter(m => m.bracket_type === 'loser') || [],
                    semifinals: response.data.semifinals.matches.filter(m => m.bracket_type === 'loser') || [],
                    finals: response.data.finals.matches.filter(m => m.bracket_type === 'loser') || []
                };

                setMatches({
                    winnerBracket: winnerMatches,
                    loserBracket: loserMatches
                });
            })
            .catch(error => {
                console.error("Error fetching playoff data:", error);
                if (error.response?.status === 404) {
                    console.log("No playoffs found for this league");
                }
            });
    }, [leagueId]);

    const formatDate = (dateString) => {
        if (!dateString) return 'TBD';
        try {
            const date = new Date(dateString);
            return new Intl.DateTimeFormat('sv-SE', {
                day: '2-digit',
                month: '2-digit',
                hour: '2-digit',
                minute: '2-digit'
            }).format(date);
        } catch (error) {
            console.error('Date formatting error:', error);
            return 'TBD';
        }
    };

    const MatchCard = ({ match }) => (
        <div className="relative mb-4 rounded-xl bg-gray-100 p-4 shadow-sm hover:shadow-md transition-all duration-300">
            <div className="space-y-2">
                <div className="flex items-center justify-between">
                    <p className="font-semibold text-gray-900 text-sm md:text-base">{match.team1_details?.team?.name || 'TBD 1'}</p>
                    <p className="text-base md:text-lg font-bold text-gray-900">{match.team1_score ?? '-'}</p>
                </div>
                <div className="flex items-center justify-between">
                    <p className="font-semibold text-gray-900 text-sm md:text-base">{match.team2_details?.team?.name || 'TBD 2'}</p>
                    <p className="text-base md:text-lg font-bold text-gray-900">{match.team2_score ?? '-'}</p>
                </div>
                {match.winner_details && (
                    <div className="mt-3 inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs md:text-sm font-medium text-green-800">
                        Vinnare: {match.winner_details.team.name}
                    </div>
                )}
            </div>
        </div>
    );

    const BracketRound = ({ matches, title, date }) => (
        <div className="mb-8">
            <h3 className="text-lg font-semibold mb-4 text-gray-700">{title} ({formatDate(date)})</h3>
            <div className="space-y-4">
                {matches.map((match, idx) => (
                    <MatchCard key={idx} match={match} />
                ))}
            </div>
        </div>
    );

    const BracketSection = ({ matches, title, dates }) => (
        <div className="mb-12">
            <h2 className="text-2xl font-bold mb-6 text-gray-800">{title}</h2>
            <div className="rounded-lg bg-gray-200 p-4 md:p-6 shadow-sm">
                {/* Mobile View */}
                <div className="md:hidden">
                    <BracketRound 
                        matches={matches.quarterfinals} 
                        title="Kvartsfinaler"
                        date={dates.quarter}
                    />
                    <BracketRound 
                        matches={matches.semifinals} 
                        title="Semifinaler"
                        date={dates.semi}
                    />
                    <BracketRound 
                        matches={matches.finals} 
                        title="Final"
                        date={dates.final}
                    />
                </div>

                {/* Desktop View */}
                <div className="hidden md:block">
                    <div className="mb-8 grid grid-cols-3 items-center border-b-2 border-gray-300 pb-4 text-center text-lg font-bold uppercase">
                        <div>Kvartsfinaler ({formatDate(dates.quarter)})</div>
                        <div>Semifinaler ({formatDate(dates.semi)})</div>
                        <div>Final ({formatDate(dates.final)})</div>
                    </div>

                    <div className="grid grid-cols-3 gap-4">
                        <div className="space-y-4">
                            {matches.quarterfinals.map((match, idx) => (
                                <MatchCard key={`quarter-${idx}`} match={match} />
                            ))}
                        </div>

                        <div className="flex flex-col justify-center space-y-8">
                            {matches.semifinals.map((match, idx) => (
                                <MatchCard key={`semi-${idx}`} match={match} />
                            ))}
                        </div>

                        <div className="flex flex-col justify-center">
                            {matches.finals.map((match, idx) => (
                                <MatchCard key={`final-${idx}`} match={match} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className="min-h-screen bg-gray-50 pt-16">
            <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                <BracketSection
                    matches={matches.winnerBracket}
                    title="A Slutspel"
                    dates={{
                        quarter: matches.winnerBracket.quarterfinals[0]?.match_date,
                        semi: matches.winnerBracket.semifinals[0]?.match_date,
                        final: matches.winnerBracket.finals[0]?.match_date
                    }}
                />

                <BracketSection
                    matches={matches.loserBracket}
                    title="B Slutspel"
                    dates={{
                        quarter: matches.loserBracket.quarterfinals[0]?.match_date,
                        semi: matches.loserBracket.semifinals[0]?.match_date,
                        final: matches.loserBracket.finals[0]?.match_date
                    }}
                />
            </div>
        </div>
    );
};

export default Playoff;