import React, { useEffect, useState } from 'react';
import { useAuth } from '../AuthContext';
import axios from 'axios';

const MyMatches = () => {
    const { user } = useAuth();
    const [leagueMatches, setLeagueMatches] = useState([]);
    const [playoffMatches, setPlayoffMatches] = useState([]);
    const [error, setError] = useState(null);
    const [statusMessages, setStatusMessages] = useState({});
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchMatches = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + '/api/mymatches/', {
                    headers: {
                        Authorization: `Token ${localStorage.getItem('authToken')}`,
                    },
                });
                setLeagueMatches(response.data.league_matches);
                setPlayoffMatches(response.data.playoff_matches);
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    setError('Du är inte medlem i något lag.');
                } else {
                    setError('Kunde inte ladda matchdata');
                }
            }
        };

        if (user) {
            fetchMatches();
        }
    }, [user]);

    const handleScoreChange = (matchId, team, score, isPlayoff) => {
        setStatusMessages(prev => ({
            ...prev,
            [matchId]: null
        }));

        if (isPlayoff) {
            setPlayoffMatches(prevMatches =>
                prevMatches.map(match =>
                    match.id === matchId ? { ...match, [`team${team}_score`]: score } : match
                )
            );
        } else {
            setLeagueMatches(prevMatches =>
                prevMatches.map(match =>
                    match.id === matchId ? { ...match, [`team${team}_score`]: score } : match
                )
            );
        }
    };

    const handleSaveResult = async (matchId, isPlayoff) => {
        const match = isPlayoff 
            ? playoffMatches.find(m => m.id === matchId)
            : leagueMatches.find(m => m.id === matchId);
            
        const updatedData = {
            team1_score: match.team1_score,
            team2_score: match.team2_score,
        };
        
        const endpoint = isPlayoff 
            ? process.env.REACT_APP_API_URL + `/api/playoff-matches/${matchId}/update_result/`
            : process.env.REACT_APP_API_URL + `/api/matches/${matchId}/update_user_result/`;

        try {
            const response = await fetch(endpoint, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(updatedData),
            });
    
            if (!response.ok) {
                throw new Error('Failed to update match result');
            }
    
            setStatusMessages(prev => ({
                ...prev,
                [matchId]: { type: 'success', message: 'Resultatet har sparats!' }
            }));

            setTimeout(() => {
                setStatusMessages(prev => ({
                    ...prev,
                    [matchId]: null
                }));
            }, 3000);

        } catch (error) {
            console.error('Error updating match result:', error);
            setStatusMessages(prev => ({
                ...prev,
                [matchId]: { type: 'error', message: 'Kunde inte spara resultatet. Försök igen.' }
            }));
        }
    };

    const getTeamName = (match, teamNumber, isPlayoff) => {
        if (isPlayoff) {
            const teamDetails = match[`team${teamNumber}_details`];
            if (teamDetails?.team?.name) {
                return teamDetails.team.name;
            } else if (teamDetails?.name) {
                return teamDetails.name;
            }
            return `Lag ${teamNumber}`;
        }
        return match[`team${teamNumber}_name`] || `Lag ${teamNumber}`;
    };

    const DesktopMatchCard = ({ match, isPlayoff }) => (
        <div className="bg-white shadow-md p-4 mb-2 rounded-lg">
            <div className="flex items-center justify-between">
                <div className="text-gray-700 w-1/5">
                    {new Date(isPlayoff ? match.match_date : match.date).toLocaleDateString()}
                </div>

                <div className="w-2/5 text-center text-gray-700">
                    {getTeamName(match, 1, isPlayoff)}
                    <span className="font-semibold mx-2">vs</span> 
                    {getTeamName(match, 2, isPlayoff)}
                </div>

                <div className="w-1/5 flex justify-center">
                    <input 
                        type="number" 
                        className="w-12 text-center border border-gray-300 rounded-md" 
                        placeholder="0"
                        value={match.team1_score || ''}
                        onChange={(e) => handleScoreChange(match.id, '1', e.target.value, isPlayoff)} 
                    /> 
                    <span className="mx-2">-</span>
                    <input 
                        type="number" 
                        className="w-12 text-center border border-gray-300 rounded-md" 
                        placeholder="0"
                        value={match.team2_score || ''}
                        onChange={(e) => handleScoreChange(match.id, '2', e.target.value, isPlayoff)} 
                    />
                </div>

                <div className="w-1/5 text-right">
                    <button 
                        className="bg-gray-600 text-white px-4 py-1 rounded-lg hover:bg-gray-800"
                        onClick={() => handleSaveResult(match.id, isPlayoff)}
                    >
                        Spara resultat
                    </button>
                </div>
            </div>
            
            {statusMessages[match.id] && (
                <div className={`mt-2 text-center ${
                    statusMessages[match.id].type === 'success' 
                        ? 'text-green-600' 
                        : 'text-red-600'
                }`}>
                    {statusMessages[match.id].message}
                </div>
            )}
        </div>
    );

    const MobileMatchCard = ({ match, isPlayoff }) => (
        <div className="bg-white shadow-md p-4 mb-4 rounded-lg">
            <div className="flex flex-col">
                <div className="text-gray-700 text-sm text-center mb-2">
                    {new Date(isPlayoff ? match.match_date : match.date).toLocaleDateString()}
                </div>

                <div className="flex flex-col items-center mb-4">
                    <div className="text-gray-700 font-medium mb-2">
                        {getTeamName(match, 1, isPlayoff)}
                    </div>
                    <div className="flex items-center justify-center w-full mb-2">
                        <input
                            type="number"
                            className="w-16 h-10 text-center border border-gray-300 rounded-md text-lg"
                            placeholder="0"
                            value={match.team1_score || ''}
                            onChange={(e) => handleScoreChange(match.id, '1', e.target.value, isPlayoff)}
                        />
                        <span className="mx-3 text-lg font-bold">-</span>
                        <input
                            type="number"
                            className="w-16 h-10 text-center border border-gray-300 rounded-md text-lg"
                            placeholder="0"
                            value={match.team2_score || ''}
                            onChange={(e) => handleScoreChange(match.id, '2', e.target.value, isPlayoff)}
                        />
                    </div>
                    <div className="text-gray-700 font-medium">
                        {getTeamName(match, 2, isPlayoff)}
                    </div>
                </div>

                <button
                    className="w-full bg-gray-600 text-white py-2 px-4 rounded-lg hover:bg-gray-800"
                    onClick={() => handleSaveResult(match.id, isPlayoff)}
                >
                    Spara resultat
                </button>

                {statusMessages[match.id] && (
                    <div className={`mt-2 text-center text-sm ${
                        statusMessages[match.id].type === 'success'
                            ? 'text-green-600'
                            : 'text-red-600'
                    }`}>
                        {statusMessages[match.id].message}
                    </div>
                )}
            </div>
        </div>
    );

    const MatchCard = ({ match, isPlayoff }) => (
        isMobile ? (
            <MobileMatchCard match={match} isPlayoff={isPlayoff} />
        ) : (
            <DesktopMatchCard match={match} isPlayoff={isPlayoff} />
        )
    );

    return (
        <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 flex flex-col">
            <div className={`container mx-auto ${isMobile ? 'p-6 pt-20' : 'p-6 pt-20'}`}>
                <h1 className={`text-2xl font-bold mb-4 ${isMobile ? 'text-center' : ''}`}>
                    Mina Matcher
                </h1>
                {error && <p className="text-red-500">{error}</p>}
                
                <h2 className={`text-xl font-semibold mb-3 ${isMobile ? 'text-center' : ''}`}>
                    Seriematcher
                </h2>
                {leagueMatches.length === 0 ? (
                    <p className={isMobile ? 'text-center' : ''}>Inga seriematcher att visa</p>
                ) : (
                    leagueMatches.map((match) => (
                        <MatchCard key={match.id} match={match} isPlayoff={false} />
                    ))
                )}

                {playoffMatches.length > 0 && (
                    <>
                        <h2 className={`text-xl font-semibold mb-3 mt-6 ${isMobile ? 'text-center' : ''}`}>
                            Slutspelsmatcher
                        </h2>
                        {playoffMatches.map((match) => (
                            <MatchCard key={match.id} match={match} isPlayoff={true} />
                        ))}
                    </>
                )}
            </div>
            <footer className="bg-gray-800 text-white text-center p-4 fixed bottom-0 w-full">
                <p>&copy; 2024 SweepScore</p>
            </footer>
        </div>
    );
};

export default MyMatches;