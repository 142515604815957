import React, { useState } from 'react';

const CreateLeague = () => {
    const [newLeague, setNewLeague] = useState({
        name: '',
        start_date: '',
        end_date: '',
        active: true
    });
    const [createPlayoffs, setCreatePlayoffs] = useState(false);
    const [hasLoserBracket, setHasLoserBracket] = useState(false);
    const [playoffDates, setPlayoffDates] = useState({
        quarterfinal: { date: '', time: '' },
        semifinal: { date: '', time: '' },
        final: { date: '', time: '' },
        loser_round1: { date: '', time: '' },
        loser_round2: { date: '', time: '' },
        loser_final: { date: '', time: '' }
    });
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');

    const getCsrfToken = () => {
        const match = document.cookie.match(/csrftoken=([^;]+)/);
        return match ? match[1] : '';
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setNewLeague((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handlePlayoffDateChange = (stage, type, value) => {
        setPlayoffDates(prev => ({
            ...prev,
            [stage]: {
                ...prev[stage],
                [type]: value
            }
        }));
    };

    const formatPlayoffDates = () => {
        if (!createPlayoffs) return {};

        const dates = {
            quarterfinal_datetime: `${playoffDates.quarterfinal.date}T${playoffDates.quarterfinal.time}`,
            semifinal_datetime: `${playoffDates.semifinal.date}T${playoffDates.semifinal.time}`,
            final_datetime: `${playoffDates.final.date}T${playoffDates.final.time}`,
        };

        if (hasLoserBracket) {
            dates.loser_round1_datetime = `${playoffDates.loser_round1.date}T${playoffDates.loser_round1.time}`;
            dates.loser_round2_datetime = `${playoffDates.loser_round2.date}T${playoffDates.loser_round2.time}`;
            dates.loser_final_datetime = `${playoffDates.loser_final.date}T${playoffDates.loser_final.time}`;
        }

        return dates;
    };

    const handleCreateLeague = async (e) => {
        e.preventDefault();

        const payload = {
            ...newLeague,
            create_playoffs: createPlayoffs,
            has_loser_bracket: hasLoserBracket,
            ...formatPlayoffDates()
        };

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/leagues/create/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCsrfToken(),
                    'Authorization': `Token ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(payload),
                credentials: 'include'
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to create league');
            }

            setSuccessMessage(`Ny serie har skapats: ${newLeague.name}`);

            // Reset form
            setNewLeague({ name: '', start_date: '', end_date: '', active: false });
            setCreatePlayoffs(false);
            setHasLoserBracket(false);
            setPlayoffDates({
                quarterfinal: { date: '', time: '' },
                semifinal: { date: '', time: '' },
                final: { date: '', time: '' },
                loser_round1: { date: '', time: '' },
                loser_round2: { date: '', time: '' },
                loser_final: { date: '', time: '' }
            });
            setError(null);
        } catch (error) {
            console.error('Misslyckades att skapa liga:', error);
            setError(error.message || 'Kunde inte skapa liga');
        }
    };

    return (
        <div className="max-w-2xl mx-auto p-4">
            <form onSubmit={handleCreateLeague} className="bg-gray-100 p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-6">Skapa ny serie</h3>

                {/* Basic League Information */}
                <div className="space-y-4 mb-6">
                    <input
                        type="text"
                        name="name"
                        placeholder="Seriens namn"
                        value={newLeague.name}
                        onChange={handleInputChange}
                        required
                        className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                    />
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm mb-1">Startdatum</label>
                            <input
                                type="date"
                                name="start_date"
                                value={newLeague.start_date}
                                onChange={handleInputChange}
                                required
                                className="w-full p-2 border rounded"
                            />
                        </div>
                        <div>
                            <label className="block text-sm mb-1">Slutdatum</label>
                            <input
                                type="date"
                                name="end_date"
                                value={newLeague.end_date}
                                onChange={handleInputChange}
                                required
                                className="w-full p-2 border rounded"
                            />
                        </div>
                    </div>
                </div>

                {/* Playoffs Section */}
                <div className="mb-6">
                    <label className="flex items-center space-x-2 w-full cursor-pointer">
                        <span>Skapa A Slutspel</span>
                        <div className="relative inline-block w-14 h-5">
                            <input
                                type="checkbox"
                                name="playoff"
                                checked={createPlayoffs}
                                onChange={(e) => setCreatePlayoffs(e.target.checked)}
                                id="playoff-switch" // Unikt ID för A Slutspel
                                className="peer appearance-none w-11 h-5 bg-slate-100 rounded-full checked:bg-slate-800 cursor-pointer transition-colors duration-300"
                            />
                            <label
                                htmlFor="playoff-switch" // Matcha med det unika ID:t
                                className="absolute top-0 left-0 w-5 h-5 bg-white rounded-full border border-slate-300 shadow-sm transition-transform duration-300 peer-checked:translate-x-6 peer-checked:border-slate-800 cursor-pointer"
                            ></label>
                        </div>
                    </label>

                    {createPlayoffs && (
                        <>
                            <label className="flex items-center space-x-2 w-full cursor-pointer">
                                <span>Skapa B Slutspel</span>
                                <div className="relative inline-block w-16 h-5">
                                    <input
                                        type="checkbox"
                                        name="loserbracket"
                                        checked={hasLoserBracket}
                                        onChange={(e) => setHasLoserBracket(e.target.checked)}
                                        id="loser-bracket-switch" // Unikt ID för B Slutspel
                                        className="peer appearance-none w-11 h-5 bg-slate-100 rounded-full checked:bg-slate-800 cursor-pointer transition-colors duration-300"
                                    />
                                    <label
                                        htmlFor="loser-bracket-switch" // Matcha med det unika ID:t
                                        className="absolute top-0 left-0 w-5 h-5 bg-white rounded-full border border-slate-300 shadow-sm transition-transform duration-300 peer-checked:translate-x-6 peer-checked:border-slate-800 cursor-pointer"
                                    ></label>
                                </div>
                            </label>

                            <div className="space-y-3 py-4">
                                <h4 className="font-medium mb-4">A Slutspel</h4>
                                {/* Quarterfinals */}
                                <div className="space-y-2">
                                    <h4 className="font-medium">Kvartsfinal</h4>
                                    <div className="grid grid-cols-2 gap-4">
                                        <input
                                            type="date"
                                            value={playoffDates.quarterfinal.date}
                                            onChange={(e) => handlePlayoffDateChange('quarterfinal', 'date', e.target.value)}
                                            required={createPlayoffs}
                                            className="w-full p-2 border rounded"
                                        />
                                        <input
                                            type="time"
                                            value={playoffDates.quarterfinal.time}
                                            onChange={(e) => handlePlayoffDateChange('quarterfinal', 'time', e.target.value)}
                                            required={createPlayoffs}
                                            className="w-full p-2 border rounded"
                                        />
                                    </div>
                                </div>

                                {/* Semifinals */}
                                <div className="space-y-2">
                                    <h4 className="font-medium">Semifinal</h4>
                                    <div className="grid grid-cols-2 gap-4">
                                        <input
                                            type="date"
                                            value={playoffDates.semifinal.date}
                                            onChange={(e) => handlePlayoffDateChange('semifinal', 'date', e.target.value)}
                                            required={createPlayoffs}
                                            className="w-full p-2 border rounded"
                                        />
                                        <input
                                            type="time"
                                            value={playoffDates.semifinal.time}
                                            onChange={(e) => handlePlayoffDateChange('semifinal', 'time', e.target.value)}
                                            required={createPlayoffs}
                                            className="w-full p-2 border rounded"
                                        />
                                    </div>
                                </div>

                                {/* Final */}
                                <div className="space-y-2">
                                    <h4 className="font-medium">Final</h4>
                                    <div className="grid grid-cols-2 gap-4">
                                        <input
                                            type="date"
                                            value={playoffDates.final.date}
                                            onChange={(e) => handlePlayoffDateChange('final', 'date', e.target.value)}
                                            required={createPlayoffs}
                                            className="w-full p-2 border rounded"
                                        />
                                        <input
                                            type="time"
                                            value={playoffDates.final.time}
                                            onChange={(e) => handlePlayoffDateChange('final', 'time', e.target.value)}
                                            required={createPlayoffs}
                                            className="w-full p-2 border rounded"
                                        />
                                    </div>
                                </div>

                                {/* Loser Bracket Section */}
                                {hasLoserBracket && (
                                    <div className="mt-6 pt-6 border-t border-gray-300">
                                        <h4 className="font-medium mb-4">B Slutspel</h4>

                                        {/* Loser Round 1 */}
                                        <div className="space-y-2 mb-4">
                                            <h4 className="font-medium text-sm">Kvartsfinal</h4>
                                            <div className="grid grid-cols-2 gap-4">
                                                <input
                                                    type="date"
                                                    value={playoffDates.loser_round1.date}
                                                    onChange={(e) => handlePlayoffDateChange('loser_round1', 'date', e.target.value)}
                                                    required={hasLoserBracket}
                                                    className="w-full p-2 border rounded"
                                                />
                                                <input
                                                    type="time"
                                                    value={playoffDates.loser_round1.time}
                                                    onChange={(e) => handlePlayoffDateChange('loser_round1', 'time', e.target.value)}
                                                    required={hasLoserBracket}
                                                    className="w-full p-2 border rounded"
                                                />
                                            </div>
                                        </div>

                                        {/* Loser Round 2 */}
                                        <div className="space-y-2 mb-4">
                                            <h4 className="font-medium text-sm">Semifinal</h4>
                                            <div className="grid grid-cols-2 gap-4">
                                                <input
                                                    type="date"
                                                    value={playoffDates.loser_round2.date}
                                                    onChange={(e) => handlePlayoffDateChange('loser_round2', 'date', e.target.value)}
                                                    required={hasLoserBracket}
                                                    className="w-full p-2 border rounded"
                                                />
                                                <input
                                                    type="time"
                                                    value={playoffDates.loser_round2.time}
                                                    onChange={(e) => handlePlayoffDateChange('loser_round2', 'time', e.target.value)}
                                                    required={hasLoserBracket}
                                                    className="w-full p-2 border rounded"
                                                />
                                            </div>
                                        </div>

                                        {/* Loser Final */}
                                        <div className="space-y-2">
                                            <h4 className="font-medium text-sm">Final</h4>
                                            <div className="grid grid-cols-2 gap-4">
                                                <input
                                                    type="date"
                                                    value={playoffDates.loser_final.date}
                                                    onChange={(e) => handlePlayoffDateChange('loser_final', 'date', e.target.value)}
                                                    required={hasLoserBracket}
                                                    className="w-full p-2 border rounded"
                                                />
                                                <input
                                                    type="time"
                                                    value={playoffDates.loser_final.time}
                                                    onChange={(e) => handlePlayoffDateChange('loser_final', 'time', e.target.value)}
                                                    required={hasLoserBracket}
                                                    className="w-full p-2 border rounded"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>

                <button
                    type="submit"
                    className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors"
                >
                    Skapa Serie
                </button>
            </form>

            {successMessage && (
                <p className="text-green-500 mt-2">{successMessage}</p>
            )}
            {error && (
                <p className="text-red-500 mt-2">{error}</p>
            )}
        </div>
    );
};

export default CreateLeague;