import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Users, Calendar, ChevronDown, ChevronUp, TrendingUp } from 'lucide-react';

const Teams = () => {
    const { leagueId } = useParams();
    const [leagueName, setLeagueName] = useState('');
    const [groupATeams, setGroupATeams] = useState([]);
    const [groupBTeams, setGroupBTeams] = useState([]);
    const [teamMatches, setTeamMatches] = useState({});
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchLeagueName = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/`);
                if (!response.ok) throw new Error('Failed to fetch league name');
                const data = await response.json();
                setLeagueName(data.name);
            } catch (error) {
                setError(error.message);
            }
        };

        const fetchTeamsInGroup = async (group) => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/teams/?group=${group}`);
                if (!response.ok) throw new Error(`Failed to fetch teams for group ${group}`);
                const data = await response.json();
                return data;
            } catch (error) {
                setError(error.message);
                return [];
            }
        };

        const fetchMatches = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/matches/`);
                if (!response.ok) throw new Error('Failed to fetch matches');
                const data = await response.json();

                // Organize matches by team
                const matchesByTeam = {};
                data.forEach(match => {
                    // Create entries for both teams if they don't exist
                    if (!matchesByTeam[match.team1_name]) matchesByTeam[match.team1_name] = [];
                    if (!matchesByTeam[match.team2_name]) matchesByTeam[match.team2_name] = [];

                    // Add match to both teams' match lists
                    matchesByTeam[match.team1_name].push({
                        id: match.id,
                        date: match.date,
                        home_team: { name: match.team1_name },
                        away_team: { name: match.team2_name },
                        home_score: match.team1_score,
                        away_score: match.team2_score,
                        status: match.team1_score !== null ? 'completed' : 'scheduled',
                        round_number: match.round_number
                    });

                    matchesByTeam[match.team2_name].push({
                        id: match.id,
                        date: match.date,
                        home_team: { name: match.team1_name },
                        away_team: { name: match.team2_name },
                        home_score: match.team1_score,
                        away_score: match.team2_score,
                        status: match.team1_score !== null ? 'completed' : 'scheduled',
                        round_number: match.round_number
                    });
                });

                setTeamMatches(matchesByTeam);
            } catch (error) {
                console.error('Error fetching matches:', error);
                setError('Failed to fetch matches');
            }
        };

        const fetchAllData = async () => {
            await fetchLeagueName();
            const [groupA, groupB] = await Promise.all([
                fetchTeamsInGroup('A'),
                fetchTeamsInGroup('B')
            ]);
            setGroupATeams(groupA);
            setGroupBTeams(groupB);
            await fetchMatches();
        };

        if (leagueId) {
            fetchAllData();
        }
    }, [leagueId]);

    const calculateTeamForm = (matches, teamName) => {
        if (!matches) return [];

        // Filter out unplayed matches (where scores are 0-0 or null)
        const playedMatches = matches.filter(match =>
            match.status === 'completed' &&
            (match.home_score !== 0 || match.away_score !== 0) &&
            match.home_score !== null &&
            match.away_score !== null
        );

        // Sort matches by date, most recent first
        const sortedMatches = playedMatches.sort((a, b) =>
            new Date(b.date) - new Date(a.date)
        );

        // Take only the 5 most recent matches
        const recentMatches = sortedMatches.slice(0, 5);

        // Calculate form for each match
        return recentMatches.map(match => {
            const isHomeTeam = match.home_team.name === teamName;
            const teamScore = isHomeTeam ? match.home_score : match.away_score;
            const opponentScore = isHomeTeam ? match.away_score : match.home_score;

            if (teamScore > opponentScore) return 'W';
            if (teamScore < opponentScore) return 'L';
            return 'D';
        }).reverse();
    };

    const getFormColor = (result) => {
        switch (result) {
            case 'W': return 'bg-green-500';
            case 'L': return 'bg-red-500';
            case 'D': return 'bg-yellow-500';
            default: return 'bg-gray-300';
        }
    };

    const TeamCard = ({ team }) => {
        const [isExpanded, setIsExpanded] = useState(false);
        const [activeTab, setActiveTab] = useState('members');
        const teamForm = calculateTeamForm(teamMatches[team.team.name], team.team.name);

        const MatchCard = ({ match, teamName }) => (
            <div className="p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors">
                {/* Desktop layout (dold på mobil) */}
                <div className="hidden md:flex items-center w-full">
                    <div className="flex-1 text-right">
                        <span className={`text-sm truncate block ${match.home_team.name === teamName ? 'font-medium' : ''}`}>
                            {match.home_team.name}
                        </span>
                    </div>
                    
                    <div className="flex flex-col items-center justify-center mx-8 min-w-[120px]">
                        <div className="flex items-center space-x-3">
                            <span className="text-lg font-semibold">
                                {match.status === 'completed' ? match.home_score : '-'}
                            </span>
                            <span className="text-gray-400 font-medium text-sm">vs</span>
                            <span className="text-lg font-semibold">
                                {match.status === 'completed' ? match.away_score : '-'}
                            </span>
                        </div>
                    </div>

                    <div className="flex-1 text-left">
                        <span className={`text-sm truncate block ${match.away_team.name === teamName ? 'font-medium' : ''}`}>
                            {match.away_team.name}
                        </span>
                    </div>
                </div>

                {/* Mobile layout (hidden on desktop) */}
                <div className="md:hidden flex flex-col items-center space-y-2">
                    {/* Home team on top */}
                    <span className={`text-sm text-center ${match.home_team.name === teamName ? 'font-medium' : ''}`}>
                        {match.home_team.name}
                    </span>
                    
                    {/* Score and date in middle */}
                    <div className="flex flex-col items-center">
                        <span className="font-bold text-lg">
                            {match.status === 'completed'
                                ? `${match.home_score} - ${match.away_score}`
                                : 'vs'}
                        </span>
                    </div>
                    
                    {/* Away team at bottom */}
                    <span className={`text-sm text-center ${match.away_team.name === teamName ? 'font-medium' : ''}`}>
                        {match.away_team.name}
                    </span>
                    <span className="text-xs text-gray-500">
                            {new Date(match.date).toLocaleDateString()}
                        </span>
                </div>

                {/* Date for desktop layout */}
                <div className="hidden md:block text-center text-xs text-gray-500 mt-1">
                    {new Date(match.date).toLocaleDateString()}
                </div>
            </div>
        );

        return (
            <div className="bg-white rounded-lg shadow-md overflow-hidden">
                <button
                    onClick={() => setIsExpanded(!isExpanded)}
                    className="w-full p-4 flex items-center justify-between hover:bg-gray-50 transition-colors"
                >
                    <h3 className="text-xl font-bold">{team.team.name}</h3>
                    {isExpanded ? (
                        <ChevronUp className="w-5 h-5 text-gray-500" />
                    ) : (
                        <ChevronDown className="w-5 h-5 text-gray-500" />
                    )}
                </button>

                {isExpanded && (
                    <div className="p-6 border-t border-gray-100">
                        {/* Custom Tabs */}
                        <div className="mb-4">
                            <div className="flex border-b border-gray-200">
                                <button
                                    onClick={() => setActiveTab('members')}
                                    className={`flex items-center gap-2 px-4 py-2 font-medium text-sm ${activeTab === 'members'
                                            ? 'border-b-2 border-blue-500 text-blue-600'
                                            : 'text-gray-500 hover:text-gray-700'
                                        }`}
                                >
                                    <Users className="w-4 h-4" />
                                    Medlemmar
                                </button>
                                <button
                                    onClick={() => setActiveTab('matches')}
                                    className={`flex items-center gap-2 px-4 py-2 font-medium text-sm ${activeTab === 'matches'
                                            ? 'border-b-2 border-blue-500 text-blue-600'
                                            : 'text-gray-500 hover:text-gray-700'
                                        }`}
                                >
                                    <Calendar className="w-4 h-4" />
                                    Matcher
                                </button>
                            </div>
                        </div>

                        {/* Tab Content */}
                        <div className="mt-4">
                            {activeTab === 'members' && (
                                <div className="space-y-2">
                                    {team.team.members?.map(member => (
                                        <div
                                            key={member.id}
                                            className="flex items-center justify-between p-2 bg-gray-50 rounded hover:bg-gray-100 transition-colors"
                                        >
                                            <span className="font-medium">
                                                {member.first_name} {member.last_name}
                                            </span>
                                            <span className="text-sm text-gray-500">{member.role}</span>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {activeTab === 'matches' && (
                                <div className="space-y-4">
                                    {/* Form Display */}
                                    <div className="flex items-center gap-2 mb-4 bg-gray-50 p-3 rounded-lg">
                                        <TrendingUp className="w-4 h-4 text-gray-600" />
                                        <span className="font-semibold">Form:</span>
                                        <div className="flex gap-1">
                                            {teamForm.map((result, index) => (
                                                <span
                                                    key={index}
                                                    className={`w-6 h-6 ${getFormColor(result)} rounded-full flex items-center justify-center text-white text-sm font-bold`}
                                                >
                                                    {result}
                                                </span>
                                            ))}
                                        </div>
                                    </div>

                                    {/* Recent Matches */}
                                    <div className="space-y-2">
                                        {teamMatches[team.team.name]?.slice(0, 10).map(match => (
                                            <MatchCard 
                                                key={match.id} 
                                                match={match} 
                                                teamName={team.team.name}
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const renderGroup = (teams, groupName) => (
        <div className="mb-8 w-full">
            <h3 className="text-xl font-bold mb-4 text-gray-800">Grupp {groupName}</h3>
            <div className="grid grid-cols-1 gap-2">
                {teams.map(team => (
                    <TeamCard key={team.id} team={team} />
                ))}
            </div>
        </div>
    );

    if (error) {
        return (
            <div className="flex justify-center items-center min-h-screen text-red-600">
                Error: {error}
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-100 pt-8">
            <main className="container mx-auto px-4 py-16">
                <h2 className="text-2xl font-bold mb-6 text-gray-800">{leagueName}</h2>
                {renderGroup(groupATeams, 'A')}
                {renderGroup(groupBTeams, 'B')}
            </main>
        </div>
    );
};

export default Teams;