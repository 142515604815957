import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const MatchResults = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [leagueMatches, setLeagueMatches] = useState({});
  const [leagues, setLeagues] = useState([]);
  const [error, setError] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  const getCsrfToken = () => {
    const match = document.cookie.match(/csrftoken=([^;]+)/);
    return match ? match[1] : '';
  };

  useEffect(() => {
    axios.defaults.headers.post['X-CSRFToken'] = getCsrfToken();

    const fetchLeaguesAndMatches = async () => {
      try {
        const leaguesResponse = await fetch(process.env.REACT_APP_API_URL + '/api/leagues/');
        if (!leaguesResponse.ok) throw new Error('Failed to fetch leagues');
        const leaguesData = await leaguesResponse.json();
        setLeagues(leaguesData);

        const allMatches = {};
        for (const league of leaguesData) {
          const matchesResponse = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${league.id}/matches/`);
          if (!matchesResponse.ok) throw new Error(`Failed to fetch matches for league ${league.name}`);
          const matchesData = await matchesResponse.json();

          // Organize matches by group and round within each league
          allMatches[league.id] = {};
          matchesData.forEach(match => {
            const { group, round_number } = match;

            // Initialize group and round objects if not yet defined
            if (!allMatches[league.id][group]) allMatches[league.id][group] = {};
            if (!allMatches[league.id][group][round_number]) allMatches[league.id][group][round_number] = [];

            // Push match into the correct group and round
            allMatches[league.id][group][round_number].push(match);
          });
        }
        setLeagueMatches(allMatches);

        // Check if the user is accessing the page from a mobile device
        const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        setIsMobile(isMobileDevice);
      } catch (error) {
        console.error('Error fetching leagues or matches:', error);
        setError('Failed to fetch leagues or matches');
      }
    };

    fetchLeaguesAndMatches();
  }, [user, navigate]);

  const handleScoreChange = (leagueId, group, round, matchId, team, value) => {
    setLeagueMatches((prevMatches) => {
      const updatedMatches = { ...prevMatches };
      const match = updatedMatches[leagueId][group][round].find(m => m.id === matchId);
      if (team === 'team1') {
        match.team1_score = value;
      } else {
        match.team2_score = value;
      }
      return updatedMatches;
    });
  };

  const handleSaveResult = async (leagueId, group, round, matchId) => {
    const match = leagueMatches[leagueId][group][round].find(m => m.id === matchId);
    const updatedData = {
      team1_score: match.team1_score,
      team2_score: match.team2_score,
    };
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/matches/${matchId}/`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify(updatedData),
      });
      if (!response.ok) throw new Error('Failed to update match result');
      alert('Resultatet har sparats!');
    } catch (error) {
      console.error('Error updating match result:', error);
      alert('Kunde inte spara resultatet. Försök igen.');
    }
  };

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Administrera matcher</h1>
      {error && <p className="text-red-500">{error}</p>}
      {leagues.length === 0 ? (
        <p>Inga ligor att visa</p>
      ) : (
        leagues.map((league) => (
          <div key={league.id} className="mb-8">
            <h2 className="text-xl font-semibold mb-4">{league.name}</h2>
            {leagueMatches[league.id] ? (
              Object.keys(leagueMatches[league.id]).map((group) => (
                <div key={group} className="mb-4">
                  <h3 className="text-lg font-semibold">Grupp: {group}</h3>
                  {Object.keys(leagueMatches[league.id][group]).map((round) => (
                    <div key={round} className="mb-4">
                      <h4 className="text-md font-semibold">Omgång: {round}</h4>
                      {isMobile ? (
                        leagueMatches[league.id][group][round].map((match) => (
                          <AdminMatchCard
                            key={match.id}
                            league={league}
                            group={group}
                            round={round}
                            match={match}
                            onScoreChange={handleScoreChange}
                            onSaveResult={handleSaveResult}
                          />
                        ))
                      ) : (
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                          {leagueMatches[league.id][group][round].map((match) => (
                            <AdminMatchCard
                              key={match.id}
                              league={league}
                              group={group}
                              round={round}
                              match={match}
                              onScoreChange={handleScoreChange}
                              onSaveResult={handleSaveResult}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ))
            ) : (
              <p>Inga matcher i denna liga</p>
            )}
          </div>
        ))
      )}
    </div>
  );
};

const AdminMatchCard = ({ league, group, round, match, onScoreChange, onSaveResult }) => {
  const handleScoreChange = (team, value) => {
    onScoreChange(league.id, group, round, match.id, team, value);
  };

  const handleSaveResult = () => {
    onSaveResult(league.id, group, round, match.id);
  };

  return (
    <div className="bg-white shadow-md p-4 mb-4 rounded-lg">
      <div className="flex flex-col">
        <div className="text-gray-700 text-sm text-center mb-2">
          {new Date(match.date).toLocaleDateString()}
        </div>

        <div className="flex flex-col items-center mb-4">
          <div className="text-gray-700 font-medium mb-2">
            {match.team1_name}
          </div>
          <div className="flex items-center justify-center w-full mb-2">
            <input
              type="number"
              className="w-16 h-10 text-center border border-gray-300 rounded-md text-lg"
              placeholder="0"
              value={match.team1_score || ''}
              onChange={(e) => handleScoreChange('team1', e.target.value)}
            />
            <span className="mx-3 text-lg font-bold">-</span>
            <input
              type="number"
              className="w-16 h-10 text-center border border-gray-300 rounded-md text-lg"
              placeholder="0"
              value={match.team2_score || ''}
              onChange={(e) => handleScoreChange('team2', e.target.value)}
            />
          </div>
          <div className="text-gray-700 font-medium">
            {match.team2_name}
          </div>
        </div>

        <button
          className="w-full bg-gray-600 text-white py-2 px-4 rounded-lg hover:bg-gray-800"
          onClick={handleSaveResult}
        >
          Spara resultat
        </button>
      </div>
    </div>
  );
};

export default MatchResults;