import React, { useEffect, useState } from 'react';
import { useAuth } from '../AuthContext';
import axios from 'axios';
import { Users, UserPlus, UserMinus } from 'lucide-react';

const MyTeam = () => {
    const { user } = useAuth();
    const [teams, setTeams] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState({});
    const [error, setError] = useState(null);

    const fetchTeams = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/api/myteams/', {
                headers: {
                    Authorization: `Token ${localStorage.getItem('authToken')}`,
                },
            });
            setTeams(response.data);
        } catch (error) {
            setError('Kunde inte ladda lagdata');
        }
    };

    const fetchUsers = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/api/users/', {
                headers: {
                    Authorization: `Token ${localStorage.getItem('authToken')}`,
                },
            });
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    useEffect(() => {
        if (user) {
            fetchTeams();
            fetchUsers();
        }
    }, [user]);

    const handleRemoveMember = async (teamId, memberUsername) => {
        try {
            await axios.delete(process.env.REACT_APP_API_URL + `/api/myteams/${teamId}/remove_member/`, {
                headers: {
                    Authorization: `Token ${localStorage.getItem('authToken')}`,
                },
                data: { username: memberUsername },
            });
            await fetchTeams(); // Uppdatera team-datan efter borttagning
        } catch (error) {
            console.error('Error removing member:', error);
        }
    };

    const handleAddMember = async (teamId) => {
        const selectedUserId = selectedUsers[teamId];
        if (selectedUserId) {
            try {
                await axios.post(process.env.REACT_APP_API_URL + `/api/myteams/${teamId}/add_member/`,
                    { userId: selectedUserId }, {
                    headers: {
                        Authorization: `Token ${localStorage.getItem('authToken')}`,
                    },
                });
                await fetchTeams(); // Uppdatera team-datan efter tillägg
                setSelectedUsers({ ...selectedUsers, [teamId]: '' }); // Återställ selection
            } catch (error) {
                console.error('Error adding member:', error);
            }
        }
    };

    const handleUserChange = (teamId, userId) => {
        setSelectedUsers({ ...selectedUsers, [teamId]: userId });
    };

    if (error) {
        return <div className="text-red-500 text-center mt-4">{error}</div>;
    }

    return (
        <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 flex flex-col">
            <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 flex justify-center items-center">
                <main className="container mx-auto px-4 py-12">
                    <div className="space-y-4">
                        {teams.map(team => (
                            <div key={team.id} className="bg-gray-200 shadow-md rounded-lg p-4 mx-auto w-full max-w-lg">
                                <h3 className="text-xl font-bold mb-2">{team.name}</h3>
                                <div className="flex items-center gap-2 mb-4">
                                    <Users className="h-4 w-4" />
                                    <h3 className="font-semibold">Spelare</h3>
                                </div>
                                <ul className="space-y-2 mb-6">
                                    {team.members.map(member => (
                                        <div key={member.id} className="flex justify-between items-center p-2 rounded-md bg-gray-100">
                                            <div>
                                                <span className="font-medium">{member.first_name} {member.last_name}</span>
                                                <span className="text-sm text-gray-500 ml-2">({member.username})</span>
                                            </div>
                                            <button
                                                onClick={() => handleRemoveMember(team.id, member.username)}
                                                className="text-red-500 hover:text-red-700"
                                            >
                                                <UserMinus className="h-4 w-4" />
                                            </button>
                                        </div>
                                    ))}
                                </ul>

                                <div className="flex items-center gap-2 mb-4">
                                    <UserPlus className="h-4 w-4" />
                                    <h3 className="font-semibold">Lägg till spelare</h3>
                                </div>
                                <div className="flex items-center mb-4">
                                    <select
                                        value={selectedUsers[team.id] || ''}
                                        onChange={(e) => handleUserChange(team.id, e.target.value)}
                                        className="bg-gray-100 border border-gray-300 rounded-md p-2 mr-2 flex-grow"
                                    >
                                        <option value="">Välj en användare</option>
                                        {users.map(user => (
                                            <option key={user.id} value={user.id}>
                                                {user.first_name} {user.last_name} - ({user.username})
                                            </option>
                                        ))}
                                    </select>
                                    <button
                                        onClick={() => handleAddMember(team.id)}
                                        className="bg-gray-600 text-white rounded-md p-2 hover:bg-gray-800 w-40"
                                    >
                                        Lägg till medlem
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </main>
            </div>
            <footer className="bg-gray-800 text-white text-center p-4 fixed bottom-0 w-full">
                <p>&copy; 2024 SweepScore</p>
            </footer>
        </div>
    );
};

export default MyTeam;