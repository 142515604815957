import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  const [leagues, setLeagues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLeagues = async () => {
      try {
        setLoading(true);
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/leagues/');
        if (!response.ok) {
          throw new Error('Failed to fetch leagues');
        }
        const data = await response.json();

        // Loop through each league to fetch teams for both groups and count them
        const updatedLeagues = await Promise.all(
          data.map(async (league) => {
            const groupATeams = await fetchTeamsInGroup(league.id, 'A');
            const groupBTeams = await fetchTeamsInGroup(league.id, 'B');
            const totalTeams = (groupATeams?.length || 0) + (groupBTeams?.length || 0);
            return { ...league, totalTeams };
          })
        );

        setLeagues(updatedLeagues);
      } catch (err) {
        setError(err.message);
        console.error('Error fetching leagues:', err);
      } finally {
        setLoading(false);
      }
    };

    const fetchTeamsInGroup = async (leagueId, group) => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/teams/?group=${group}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch teams for group ${group} in league ${leagueId}`);
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.error(`Error fetching teams in group ${group} for league ${leagueId}:`, error);
        return [];
      }
    };

    fetchLeagues();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <img
          src={require('../images/curling.gif')}
          alt="Loading..."
          className="w-24 h-24"
        />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-xl text-red-600">Error: {error}</div>
      </div>
    );
  }
  const archivedLeagues = leagues.filter(league => !league.active);
  const activeLeagues = leagues.filter(league => league.active);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center pt-16">
      <main className="flex-grow container mx-auto px-4 py-8">
        <h2 className="text-2xl font-bold mb-6">Aktiva tävlingar</h2>
        {activeLeagues.length === 0 ? (
          <div className="text-center text-gray-600">No active leagues found</div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            {activeLeagues.map((league) => (
              <Link to={`/league/${league.id}`} key={league.id}>
                <div className="league-card bg-white shadow-md rounded-lg p-4 hover:shadow-lg transition-shadow">
                  <h3 className="text-xl font-semibold">{league.name}</h3>
                  <p className="text-gray-600">
                    {new Date(league.start_date).toLocaleDateString()} - {new Date(league.end_date).toLocaleDateString()}
                  </p>
                  <div className="flex space-x-3 mt-4">
                    <span className="inline-block px-2 py-1 text-sm bg-green-100 text-green-800 rounded">
                      Aktiv
                    </span>
                    <span className="inline-block px-2 py-1 text-sm bg-green-100 text-green-800 rounded">
                      Antal lag: {league.totalTeams}
                    </span>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        )}

        {archivedLeagues.length === 0 ? (
          <>
          </>

        ) : (
          <>
            <h4 className="text-2xl font-bold mb-6 py-4">Avslutade tävlingar</h4>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">

              {archivedLeagues.map((league) => (
                <Link to={`/league/${league.id}`} key={league.id}>
                  <div className="league-card bg-white shadow-md rounded-lg p-4 hover:shadow-lg transition-shadow">
                    <h3 className="text-xl font-semibold">{league.name}</h3>
                    <p className="text-gray-600">
                      {new Date(league.start_date).toLocaleDateString()} - {new Date(league.end_date).toLocaleDateString()}
                    </p>
                    <div className="flex space-x-3 mt-4">
                      <span className="inline-block px-2 py-1 text-sm bg-red-100 text-red-800 rounded">
                        Avslutad
                      </span>
                      <span className="inline-block px-2 py-1 text-sm bg-red-100 text-red-800 rounded">
                        Antal lag: {league.totalTeams}
                      </span>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </>



        )}
      </main>

      <footer className="bg-gray-800 text-white text-center p-4 w-full mt-auto">
        <p>&copy; 2024 SweepScore</p>
      </footer>
    </div>
  );
};

export default Home;