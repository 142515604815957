import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ActiveLeagues = () => {
    const { user } = useAuth();
    const [leagues, setLeagues] = useState([]);
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const getCsrfToken = () => {
        const match = document.cookie.match(/csrftoken=([^;]+)/);
        return match ? match[1] : '';
    };
    
    useEffect(() => {

        axios.defaults.headers.post['X-CSRFToken'] = getCsrfToken();

        const fetchLeaguesAndMatches = async () => {
            try {
                // Fetch all active leagues
                const leaguesResponse = await fetch(process.env.REACT_APP_API_URL + '/api/leagues/');
                if (!leaguesResponse.ok) {
                    throw new Error('Failed to fetch leagues');
                }
                const leaguesData = await leaguesResponse.json();
                setLeagues(leaguesData);

            } catch (error) {
                console.error('Error fetching leagues or matches:', error);
                setError('Failed to fetch leagues or matches');
            }
        };

        fetchLeaguesAndMatches();
    }, [user, navigate]);

    const activeLeagues = leagues.filter(league => league.active);

    return (
        <div>
            <h2 className="text-xl font-semibold mb-4">Aktiva Ligor</h2>
            {error && <p className="text-red-500">{error}</p>}
            {activeLeagues.length === 0 ? (
                <p>Inga aktiva ligor att visa</p>
            ) : (
                activeLeagues.map((league) => (
                    <div key={league.id} className="mb-4">
                        <h3>{league.name}</h3>
                        <p>Startdatum: {league.start_date}</p>
                        <p>Slutdatum: {league.end_date}</p>
                    </div>
                ))
            )}
        </div>
    );
}

export default ActiveLeagues;