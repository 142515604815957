import React from 'react';

const Overview = () => {
  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-xl font-bold mb-2">Översikt</h2>
      <p className="text-gray-600">Här kan du se en sammanfattning av administratörsdata.</p>
    </div>
  );
};

export default Overview;
