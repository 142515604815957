import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { User, Mail, Save } from 'lucide-react';

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/api/users/', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${localStorage.getItem('authToken')}`
        }
      });
      setUsers(response.data);
    } catch (error) {
      setError('Error fetching users');
    }
  };

  const handleSaveUser = async (userToSave) => {
    try {  
        await axios.put(process.env.REACT_APP_API_URL + `/api/users/admin/update/${userToSave.id}/`, userToSave, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${localStorage.getItem('authToken')}`
          }
        });
      
      setEditingUser(null);
      setSuccessMessage('User updated successfully');
      fetchUsers();
    } catch (error) {
      if (error.response.data.username) {
        setError('Username already exists');
      } else if (error.response.data.email) {
        setError('Email already exists');
      } else {
        setError('Error updating user');
      }
    }
  };

  return (
    <div className="max-w-4xl mx-auto py-8">
      <h2 className="text-2xl font-bold mb-6">Manage Users</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {users.map((userItem) => (
          <div key={userItem.id} className="bg-white shadow-md rounded-md p-4">
            <div className="flex items-center mb-2">
              <User size={28} className="mr-2" />
              {editingUser?.id === userItem.id ? (
                <input
                  type="text"
                  value={editingUser.username}
                  onChange={(e) =>
                    setEditingUser({ ...editingUser, username: e.target.value })
                  }
                  className="px-2 py-1 border rounded w-full"
                />
              ) : (
                <h3 className="text-lg font-medium">{userItem.username}</h3>
              )}
            </div>
            <div className="flex items-center mb-2">
              <Mail size={28} className="mr-2" />
              {editingUser?.id === userItem.id ? (
                <input
                  type="email"
                  value={editingUser.email}
                  onChange={(e) =>
                    setEditingUser({ ...editingUser, email: e.target.value })
                  }
                  className="px-2 py-1 border rounded w-full"
                />
              ) : (
                <p className="text-gray-500">{userItem.email}</p>
              )}
            </div>
            <div className="flex justify-end">
              {editingUser?.id === userItem.id ? (
                <>
                  <button
                    onClick={() => handleSaveUser(editingUser)}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                  >
                    <Save size={20} className="mr-2" />
                    Save
                  </button>
                  <button
                    onClick={() => setEditingUser(null)}
                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <button
                  onClick={() => setEditingUser(userItem)}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  <User size={20} className="mr-2" />
                  Edit
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
      {successMessage && (
        <div className="mt-4 text-green-500">{successMessage}</div>
      )}
      {error && (
        <div className="mt-4 text-red-500">{error}</div>
      )}
    </div>
  );
};

export default ManageUsers;