import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ChevronDown, ChevronUp } from 'lucide-react';

const MatchesPage = () => {
    const { leagueId } = useParams();
    const [leagueName, setLeagueName] = useState('');
    const [groupedMatches, setGroupedMatches] = useState({});
    const [error, setError] = useState(null);
    const [expandedRound, setExpandedRound] = useState(null);

    useEffect(() => {
        const fetchLeagueName = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/`);
                if (!response.ok) {
                    throw new Error('Failed to fetch league name');
                }
                const data = await response.json();
                setLeagueName(data.name);
            } catch (error) {
                console.error('Error fetching league name:', error);
                setError(error.message);
            }
        };

        const fetchMatches = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/matches/`);
                if (!response.ok) throw new Error('Network response was not ok');
                
                const data = await response.json();
                const matchesByGroupAndRound = {};

                data.forEach(match => {
                    const { group, round_number } = match;
                    if (!matchesByGroupAndRound[group]) matchesByGroupAndRound[group] = {};
                    if (!matchesByGroupAndRound[group][round_number]) matchesByGroupAndRound[group][round_number] = [];
                    matchesByGroupAndRound[group][round_number].push(match);
                });

                setGroupedMatches(matchesByGroupAndRound);
            } catch (error) {
                console.error('Error fetching matches:', error);
                setError('Failed to fetch matches');
            }
        };
        
        fetchLeagueName();
        fetchMatches();
    }, [leagueId]);

    const handleRoundClick = (roundId) => {
        setExpandedRound(expandedRound === roundId ? null : roundId);
      };
    
      const renderMatch = (match) => (
        <div key={match.id} className="bg-gray-100 rounded-lg shadow-sm p-4 hover:shadow-md transition-shadow">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="flex-1 text-center md:text-right mb-2 md:mb-0">
              <span className="font-medium text-gray-800 truncate max-w-[150px] md:max-w-none">
                {match.team1_name || 'Unknown Team A'}
              </span>
            </div>
    
            <div className="flex items-center justify-center mx-4 min-w-[100px]">
              <div className="flex items-center space-x-3">
                <span className="text-2xl font-semibold text-gray-800">
                  {match.team1_score !== null ? match.team1_score : '-'}
                </span>
                <span className="text-gray-400 font-medium">vs</span>
                <span className="text-2xl font-semibold text-gray-800">
                  {match.team2_score !== null ? match.team2_score : '-'}
                </span>
              </div>
            </div>
    
            <div className="flex-1 text-center md:text-left mt-2 md:mt-0">
              <span className="font-medium text-gray-800 truncate max-w-[150px] md:max-w-none">
                {match.team2_name || 'Unknown Team B'}
              </span>
            </div>
          </div>
        </div>
      );
    
      const renderMatchesGroup = (groupMatches, groupName) => (
        <div className="mb-8">
          <h3 className="text-xl font-bold mb-4 text-gray-800">Matcher för Grupp {groupName}</h3>
          <div className="space-y-4">
            {Object.keys(groupMatches).map(round_number => {
              const roundId = `${groupName}-${round_number}`;
              return (
                <div key={roundId} className="border border-gray-300 rounded-lg overflow-hidden bg-gray-200">
                  <button
                    onClick={() => handleRoundClick(roundId)}
                    className="w-full px-6 py-4 flex items-center justify-between bg-gray-50 hover:bg-gray-200 transition-colors duration-150"
                  >
                    <span className="text-lg font-semibold text-gray-800">
                      Omgång {round_number}
                    </span>
                    {expandedRound === roundId ? 
                      <ChevronUp className="w-5 h-5 text-gray-500" /> : 
                      <ChevronDown className="w-5 h-5 text-gray-500" />
                    }
                  </button>
    
                  <div className={`
                    space-y-3 p-4
                    transition-all duration-200 ease-in-out
                    ${expandedRound === roundId ? 'block' : 'hidden'}
                  `}>
                    {groupMatches[round_number].map(match => renderMatch(match))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    
      if (error) {
        return (
          <div className="flex justify-center items-center min-h-screen text-red-600">
            Error: {error}
          </div>
        );
      }
    
      return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center pt-16">
          <main className="flex-grow container mx-auto px-4 py-8">
            <h2 className="text-2xl font-bold mb-6 text-gray-800">{leagueName}</h2>
    
            {groupedMatches['A'] && renderMatchesGroup(groupedMatches['A'], 'A')}
            {groupedMatches['B'] && renderMatchesGroup(groupedMatches['B'], 'B')}
          </main>
    
          <footer className="bg-gray-800 text-white text-center p-4 w-full mt-auto">
            <p>&copy; 2024 SweepScore</p>
          </footer>
        </div>
      );
    };
    
    export default MatchesPage;