import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CreateUser = () => {
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: ''
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (success) {
      console.log('Player created successfully!');
    }
  }, [success]); // Körs när success ändras

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccess(false);

    const payload = {
      first_name: userData.firstName,
      last_name: userData.lastName
    };

    if (userData.email) {
      payload.email = userData.email;
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + '/api/users/create/',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${localStorage.getItem('authToken')}`
          },
          withCredentials: true
        }
      );

      if (response.status === 201) {
        setSuccess(true);
        setError(null);
        setUserData({
          firstName: '',
          lastName: '',
          email: ''
        });
      }
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to create user');
    }
  };

  return (
    <div className="max-w-md mx-auto p-6">
      <h2 className="text-2xl font-bold mb-6">Skapa ny användare</h2>
      {success && (
        <p className="text-green-500">Användare skapad!</p>  // Visar feedback för användaren
      )}
      {error && (
        <p className="text-red-500">{error}</p> // Visar felmeddelanden
      )}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium mb-1">Förnamn</label>
          <input
            type="text"
            name="firstName"
            value={userData.firstName}
            onChange={handleInputChange}
            className="w-full p-2 border rounded-md"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">Efternamn</label>
          <input
            type="text"
            name="lastName"
            value={userData.lastName}
            onChange={handleInputChange}
            className="w-full p-2 border rounded-md"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">Epost (Behövs för att kunna logga in)</label>
          <input
            type="email"
            name="email"
            value={userData.email}
            onChange={handleInputChange}
            className="w-full p-2 border rounded-md"
          />
        </div>
        <button
          type="submit"
          className="w-full py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          Skapa användare
        </button>
      </form>
    </div>
  );
};

export default CreateUser;
