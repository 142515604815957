import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { ChevronDown, ChevronUp } from 'lucide-react';

const PlayoffMatches = () => {
    const [matches, setMatches] = useState([]);
    const [loading, setLoading] = useState(true);
    const [groupATeams, setGroupATeams] = useState({});
    const [groupBTeams, setGroupBTeams] = useState({});
    const [leagues, setLeagues] = useState([]);
    const [expandedLeagues, setExpandedLeagues] = useState({});

    const getCsrfToken = useCallback(() => {
        return 'din-csrf-token';
    }, []);

    const fetchTeamsInGroup = useCallback(async (leagueId, group) => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/teams/?group=${group}`, {
                headers: {
                    'Authorization': `Token ${localStorage.getItem('authToken')}`
                }
            });
            return response.data;
        } catch (error) {
            console.error(`Error fetching teams in group ${group} for league ${leagueId}:`, error);
            throw error;
        }
    }, []);

    const fetchTeamsForAllLeagues = useCallback(async () => {
        const groupAResults = {};
        const groupBResults = {};
    
        for (const league of leagues) {
            try {
                const [groupA, groupB] = await Promise.all([
                    fetchTeamsInGroup(league.id, 'A'),
                    fetchTeamsInGroup(league.id, 'B')
                ]);
                groupAResults[league.id] = groupA;
                groupBResults[league.id] = groupB;
            } catch (error) {
                console.error(`Error fetching teams for league ${league.name}:`, error);
                groupAResults[league.id] = [];
                groupBResults[league.id] = [];
            }
        }
    
        setGroupATeams(groupAResults);
        setGroupBTeams(groupBResults);
    }, [leagues, fetchTeamsInGroup]);

    const fetchPlayoffMatches = useCallback(async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/api/playoffmatches/', {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCsrfToken(),
                    'Authorization': `Token ${localStorage.getItem('authToken')}`
                },
                withCredentials: true,
            });
            setMatches(response.data);
            await fetchTeamsForAllLeagues();
        } catch (error) {
            console.error("There was an error fetching the playoff matches!", error);
        }
    }, [getCsrfToken, fetchTeamsForAllLeagues]);

    const fetchLeagues = useCallback(async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/api/leagues/', {
                headers: {
                    'Authorization': `Token ${localStorage.getItem('authToken')}`
                },
            });
            setLeagues(response.data);
    
            const initialExpandedState = {};
            response.data.forEach(league => {
                initialExpandedState[league.id] = false;
            });
            setExpandedLeagues(initialExpandedState);
            setLoading(false);
        } catch (error) {
            console.error("There was an error fetching the leagues!", error);
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchLeagues();
    }, [fetchLeagues]);
    
    useEffect(() => {
        if (leagues.length > 0) {
            fetchPlayoffMatches();
        }
    }, [leagues, fetchPlayoffMatches]);

    const handleScoreUpdate = async (id, team1Score, team2Score) => {
        const match = matches.find(m => m.id === id);
    
        try {
            const response = await axios.patch(process.env.REACT_APP_API_URL + `/api/playoffmatches/${id}/`, {
                team1_score: team1Score,
                team2_score: team2Score,
                team1: match.team1?.id,
                team2: match.team2?.id
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCsrfToken(),
                    'Authorization': `Token ${localStorage.getItem('authToken')}`
                },
                withCredentials: true,
            });
    
            setMatches(prevMatches => 
                prevMatches.map(m => 
                    m.id === id ? response.data : m
                )
            );
        } catch (error) {
            console.error("There was an error updating the match!", error);
            console.error("Error details:", error.response?.data);
        }
    };

    const handleMatchChange = async (matchId, teamField, selectedValue) => {
        const match = matches.find(m => m.id === matchId);

        if (teamField === 'team1' || teamField === 'team2') {
            const updateData = {
                team1_score: match.team1_score || 0,
                team2_score: match.team2_score || 0,
                team1: teamField === 'team1' ? (selectedValue ? parseInt(selectedValue) : null) : match.team1?.id,
                team2: teamField === 'team2' ? (selectedValue ? parseInt(selectedValue) : null) : match.team2?.id
            };

            try {
                const response = await axios.patch(
                    process.env.REACT_APP_API_URL + `/api/playoffmatches/${matchId}/`,
                    updateData,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'X-CSRFToken': getCsrfToken(),
                            'Authorization': `Token ${localStorage.getItem('authToken')}`
                        },
                        withCredentials: true,
                    }
                );

                setMatches(prevMatches =>
                    prevMatches.map(m =>
                        m.id === matchId ? response.data : m
                    )
                );
            } catch (error) {
                console.error("Error updating match:", error);
                setMatches(prevMatches =>
                    prevMatches.map(m =>
                        m.id === matchId ? match : m
                    )
                );
            }
        } else {
            setMatches(prevMatches =>
                prevMatches.map(m =>
                    m.id === matchId ? { ...m, [teamField]: selectedValue } : m
                )
            );
        }
    };

    const toggleLeague = (leagueId) => {
        setExpandedLeagues(prev => ({
            ...prev,
            [leagueId]: !prev[leagueId]
        }));
    };

    const getLeagueMatches = (leagueId) => {
        return matches.filter(match => match.league === leagueId);
    };

    if (loading) return (
        <div className="flex items-center justify-center min-h-screen">
            <p className="text-lg">Loading...</p>
        </div>
    );

    const MatchesTable = ({ leagueMatches, leagueId }) => (
        <div className="overflow-x-auto">
            {leagueMatches.length === 0 ? (
                <p className="p-4 text-gray-500">No matches found for this league.</p>
            ) : (
                <table className="w-full border-collapse">
                    <thead className="bg-gray-100">
                    </thead>
                    <tbody>
                        {leagueMatches.map(match => (
                            <tr key={match.id} className="hover:bg-gray-50">
                                <td className="p-2 border">{match.playoff?.name}</td>
                                <td className="p-2 border">{match.match_number}</td>
                                <td className="p-2 border">
                                    <select
                                        className="w-full p-1 border rounded"
                                        value={match.team1?.id || ''}
                                        onChange={(e) => handleMatchChange(match.id, 'team1', e.target.value)}
                                    >
                                        <option value="">Select Team</option>
                                        {[...(groupATeams[match.league] || []), ...(groupBTeams[match.league] || [])].map(team => (
                                            <option key={team.id} value={team.id}>{team.team.name}</option>
                                        ))}
                                    </select>
                                </td>
                                <td className="p-2 border">
                                    <input
                                        type="number"
                                        className="w-20 p-1 border rounded"
                                        value={match.team1_score || 0}
                                        onChange={(e) => handleMatchChange(match.id, 'team1_score', parseInt(e.target.value) || 0)}
                                    />
                                </td>
                                <td className="p-2 border">
                                    <input
                                        type="number"
                                        className="w-20 p-1 border rounded"
                                        value={match.team2_score || 0}
                                        onChange={(e) => handleMatchChange(match.id, 'team2_score', parseInt(e.target.value) || 0)}
                                    />
                                </td>
                                <td className="p-2 border">
                                    <select
                                        className="w-full p-1 border rounded"
                                        value={match.team2?.id || ''}
                                        onChange={(e) => handleMatchChange(match.id, 'team2', e.target.value)}
                                    >
                                        <option value="">Select Team</option>
                                        {[...(groupATeams[match.league] || []), ...(groupBTeams[match.league] || [])].map(team => (
                                            <option key={team.id} value={team.id}>{team.team.name}</option>
                                        ))}
                                    </select>
                                </td>
                                <td className="p-2 border">
                                    <button
                                        className="px-4 py-1 text-white bg-blue-500 rounded hover:bg-blue-600 transition-colors"
                                        onClick={() => handleScoreUpdate(match.id, match.team1_score, match.team2_score)}
                                    >
                                        Save
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );

    const PlayoffMatchesMobile = ({ matches, handleScoreUpdate, handleMatchChange, groupATeams, groupBTeams }) => {
        return (
          <div className="p-4 space-y-4">
            {matches.map(match => (
              <div key={match.id} className="border rounded-lg shadow-sm overflow-hidden">
                <div className="bg-gray-50 p-4">
                  <h3 className="text-xl font-semibold">{match.playoff?.name} - Match {match.match_number}</h3>
                </div>
                <div className="p-6 space-y-6">
                  <div>
                    <label htmlFor={`team1-${match.id}`} className="block font-medium mb-2">
                      Team 1
                    </label>
                    <div className="flex items-center space-x-4">
                      <select
                        id={`team1-${match.id}`}
                        className="flex-1 p-3 border rounded"
                        value={match.team1?.id || ''}
                        onChange={(e) => handleMatchChange(match.id, 'team1', e.target.value)}
                      >
                        <option value="">Select Team</option>
                        {[...(groupATeams[match.league] || []), ...(groupBTeams[match.league] || [])].map(team => (
                          <option key={team.id} value={team.id}>{team.team.name}</option>
                        ))}
                      </select>
                      <input
                        type="number"
                        className="w-24 p-3 border rounded"
                        value={match.team1_score || 0}
                        onChange={(e) => handleMatchChange(match.id, 'team1_score', parseInt(e.target.value) || 0)}
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor={`team2-${match.id}`} className="block font-medium mb-2">
                      Team 2
                    </label>
                    <div className="flex items-center space-x-4">
                      <select
                        id={`team2-${match.id}`}
                        className="flex-1 p-3 border rounded"
                        value={match.team2?.id || ''}
                        onChange={(e) => handleMatchChange(match.id, 'team2', e.target.value)}
                      >
                        <option value="">Select Team</option>
                        {[...(groupATeams[match.league] || []), ...(groupBTeams[match.league] || [])].map(team => (
                          <option key={team.id} value={team.id}>{team.team.name}</option>
                        ))}
                      </select>
                      <input
                        type="number"
                        className="w-24 p-3 border rounded"
                        value={match.team2_score || 0}
                        onChange={(e) => handleMatchChange(match.id, 'team2_score', parseInt(e.target.value) || 0)}
                      />
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <button
                      className="px-6 py-3 text-white bg-blue-500 rounded hover:bg-blue-600 transition-colors"
                      onClick={() => handleScoreUpdate(match.id, match.team1_score, match.team2_score)}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      };

      return (
        <div className="p-6">
          <h2 className="text-2xl font-bold mb-6">Slutspelsmatcher</h2>
          <div className="space-y-4">
            {leagues.map(league => (
              <div key={league.id} className="border rounded-lg shadow-sm overflow-hidden">
                <div 
                  className="bg-gray-50 p-4 cursor-pointer flex items-center justify-between hover:bg-gray-100 transition-colors"
                  onClick={() => toggleLeague(league.id)}
                >
                  <h3 className="text-xl font-semibold">{league.name}</h3>
                  {expandedLeagues[league.id] ? (
                    <ChevronUp className="h-5 w-5 text-gray-600" />
                  ) : (
                    <ChevronDown className="h-5 w-5 text-gray-600" />
                  )}
                </div>
                {expandedLeagues[league.id] && (
                  <div className="p-4">
                    {/* Använd PlayoffMatchesMobile för små skärmar */}
                    {window.innerWidth < 768 ? (
                      <PlayoffMatchesMobile
                        matches={getLeagueMatches(league.id)}
                        handleScoreUpdate={handleScoreUpdate}
                        handleMatchChange={handleMatchChange}
                        groupATeams={groupATeams}
                        groupBTeams={groupBTeams}
                      />
                    ) : (
                      /* Använd MatchesTable för större skärmar */
                      <MatchesTable
                        leagueMatches={getLeagueMatches(league.id)}
                        leagueId={league.id}
                      />
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      );
};

export default PlayoffMatches;