import React from 'react';
import ActiveLeagues from './ActiveLeagues';
import CreateLeague from './CreateLeague';
import ArchivedLeagues from './ArchivedLeagues';
import SubTabButton from '../Common/SubTabButton';

const LeaguesTab = ({ activeSubTab }) => {
  const openSubTab = (event, tabName) => {
    const tabcontent = document.getElementsByClassName("tabcontent");
    for (let i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    const tablinks = document.getElementsByClassName("tablinks");
    for (let i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }

    document.getElementById(tabName).style.display = "block";
    event.currentTarget.className += " active";
  };

  return (
    <div>
      <div className="subtab">
        <SubTabButton onClick={(e) => openSubTab(e, 'ActiveLeagues')} label="Serier" />
        <SubTabButton onClick={(e) => openSubTab(e, 'CreateLeague')} label="Skapa serie" />
        <SubTabButton onClick={(e) => openSubTab(e, 'ArchivedLeagues')} label="Arkiv" />
      </div>

      <div id="ActiveLeagues" className="tabcontent" style={{ display: activeSubTab === 'ActiveLeagues' ? 'block' : 'none' }}>
        <ActiveLeagues />
      </div>

      <div id="CreateLeague" className="tabcontent" style={{ display: activeSubTab === 'CreateLeague' ? 'block' : 'none' }}>
        <CreateLeague />
      </div>

      <div id="ArchivedLeagues" className="tabcontent" style={{ display: activeSubTab === 'ArchivedLeagues' ? 'block' : 'none' }}>
        <ArchivedLeagues />
      </div>
    </div>
  );
};

export default LeaguesTab;
