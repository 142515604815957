import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

const LeagueDetail = () => {
  const { id } = useParams();
  const [league, setLeague] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + `/api/leagues/${id}/`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch league data');
        }
        return response.json();
      })
      .then(data => {
        setLeague(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching league:', error);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <div className="flex justify-center items-center min-h-screen">Loading...</div>;
  }

  if (!league) {
    return <div className="flex justify-center items-center min-h-screen">League not found</div>;
  }

  const navigationLinks = [
    { to: `/league/${id}/matches`, text: 'Matcher' },
    { to: `/league/${id}/table`, text: 'Tabell' },
    { to: `/league/${id}/playoff`, text: 'Slutspel' },
    { to: `/league/${id}/teams`, text: 'Lag' }
  ];

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center pt-16">
      <main className="flex-grow container mx-auto px-4 py-8">
        {/* League Info */}
        <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-lg mb-6">
          <h2 className="text-2xl font-bold mb-4">{league.name}</h2>
          <p className="text-gray-700">Startdatum: <span className="font-semibold">{league.start_date}</span></p>
          <p className="text-gray-700">Slutdatum: <span className="font-semibold">{league.end_date}</span></p>
        </div>

        {/* Navigation Links - Only visible on mobile */}
        {isMobile && (
          <div className="mb-6 px-4">
            <ul className="flex flex-col gap-3">
              {navigationLinks.map((link) => (
                <li key={link.to} className="w-full">
                  <Link 
                    to={link.to} 
                    className="block w-full text-center py-3.5 bg-white text-gray-800 font-medium rounded-xl active:bg-gray-100 transition-colors shadow-sm border border-gray-200"
                  >
                    {link.text}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </main>
      
      <footer className="bg-gray-800 text-white text-center p-4 w-full mt-auto">
        <p>&copy; 2024 SweepScore</p>
      </footer>
    </div>
  );
};

export default LeagueDetail;