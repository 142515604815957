import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Users, Trophy, UserPlus, UserMinus } from 'lucide-react';

const AllTeams = () => {
  const [teams, setTeams] = useState([]);
  const [expandedTeamId, setExpandedTeamId] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/api/teams/', {
          headers: {
            'Authorization': `Token ${localStorage.getItem('authToken')}`,
          },
        });
        setTeams(response.data);
      } catch (error) {
        console.error('Error fetching teams:', error);
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/api/users/', {
          headers: {
            'Authorization': `Token ${localStorage.getItem('authToken')}`,
          },
        });
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchTeams();
    fetchUsers();
  }, []);

  const handleToggleExpand = async (teamId) => {
    if (expandedTeamId === teamId) {
      setExpandedTeamId(null);
    } else {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/api/teams/${teamId}/`, {
          headers: {
            'Authorization': `Token ${localStorage.getItem('authToken')}`,
          },
        });
        const updatedTeams = teams.map(team =>
          team.id === teamId ? { ...team, active_leagues: response.data.active_leagues } : team
        );
        setTeams(updatedTeams);
        setExpandedTeamId(teamId);
      } catch (error) {
        console.error('Error fetching team details:', error);
      }
    }
  };

  const handleAddMember = async (teamId) => {
    try {
      await axios.post(process.env.REACT_APP_API_URL + `/api/teams/${teamId}/add-member/`, {
        user_id: selectedUser,
        role: 'Spelare',
      }, {
        headers: {
          'Authorization': `Token ${localStorage.getItem('authToken')}`,
        },
      });
  
      // Uppdatera teams-tillstånd efter att medlemmen har lagts till
      const updatedTeamsResponse = await axios.get(process.env.REACT_APP_API_URL + '/api/teams/', {
        headers: {
          'Authorization': `Token ${localStorage.getItem('authToken')}`,
        },
      });
      setTeams(updatedTeamsResponse.data);
    } catch (error) {
      console.error('Error adding team member:', error);
    }
  };

  return (
    <div className="container mx-auto py-6 px-4">
      <div className="flex items-center gap-2 mb-6">
        <Users className="h-6 w-6" />
        <h1 className="text-2xl font-bold">Alla lag</h1>
      </div>

      <div className="space-y-4">
        {teams.map((team) => (
          <div key={team.id} className="border rounded-lg bg-white shadow-sm">
            <button
              className="flex justify-between items-center w-full px-4 py-2 text-left hover:bg-gray-100"
              onClick={() => handleToggleExpand(team.id)}
            >
              <div className="flex items-center gap-4">
                <span className="text-xl font-semibold">{team.name}</span>
                <span className="text-sm text-gray-500">{team.members.length} Spelare</span>
              </div>
            </button>

            {expandedTeamId === team.id && (
              <div className="px-4 pb-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="p-4 border rounded-md bg-gray-50">
                    <div className="flex items-center gap-2 mb-4">
                      <Users className="h-4 w-4" />
                      <h3 className="font-semibold">Spelare</h3>
                    </div>
                    <div className="space-y-2">
                      {team.members.map((member) => (
                        <div key={member.id} className="flex justify-between items-center p-2 rounded-md bg-gray-100">
                          <div>
                            <span className="font-medium">{member.username}</span>
                            <span className="text-sm text-gray-500 ml-2">({member.first_name} {member.last_name})</span>
                          </div>
                          <button 
                            onClick={() => console.log(`Remove member ID ${member.id}`)}
                            className="text-red-500 hover:text-red-700"
                          >
                            <UserMinus className="h-4 w-4" />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="p-4 border rounded-md bg-gray-50">
                    <div className="space-y-6">
                      <div>
                        <div className="flex items-center gap-2 mb-4">
                          <Trophy className="h-4 w-4" />
                          <h3 className="font-semibold">Aktiva serier</h3>
                        </div>
                        <div className="space-y-1">
                          {team.active_leagues && team.active_leagues.map((league) => (
                            <div key={league.id} className="p-2 rounded-md bg-gray-100">
                              {league.name}
                            </div>
                          ))}
                        </div>
                      </div>

                      <div>
                        <div className="flex items-center gap-2 mb-4">
                          <UserPlus className="h-4 w-4" />
                          <h3 className="font-semibold">Lägg till spelare</h3>
                        </div>
                        <div className="flex gap-2">
                          <select
                            value={selectedUser}
                            onChange={(e) => setSelectedUser(e.target.value)}
                            className="border border-gray-300 rounded-md p-2 mr-2 flex-grow"
                          >
                            <option value="" disabled>Välj spelare</option>
                            {users.map((user) => (
                              <option key={user.id} value={user.id}>
                                {user.username} - {user.first_name} {user.last_name}
                              </option>
                            ))}
                          </select>
                          <button
                            onClick={() => handleAddMember(team.id)}
                            className="bg-blue-500 text-white rounded-md p-2 hover:bg-blue-600 w-40"
                          >
                            Spara
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllTeams;